import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="brand-name">Guru</Link>
      <ul className="nav-links">
        <li><Link to="/showimages">Show Images</Link></li>
        <li><Link to="/form">FOrm</Link></li>
        <li><Link to="/showform">Show Forms</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;

import React, { useEffect, useState } from 'react'
import { collection, getDocs } from "firebase/firestore"
import { database } from '../firebaseConfig';

const ShowImages = () => {
  const [images, setImages] = useState([]);
  const imagesRef = collection(database, "images");

  useEffect(() => {
    const fetchImages = async () => {
      const imagesCollection = await getDocs(imagesRef);
      setImages(imagesCollection.docs.map(doc => {
        return { ...doc.data(), id: doc.id };
      }));
    };

    fetchImages();
  }, []);

  return (
    <div>
      {images.map(image => (
        <div key={image.id}>
          <img src={image.url} alt={image.name} />
        </div>
      ))}
    </div>
  )
}

export default ShowImages;

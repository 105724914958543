import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getMessaging,  getToken } from 'firebase/messaging';
import { database, storage, messaging } from '../firebaseConfig';
import { requestUserPermission } from '../Notifications/requestPermission';
import {initializeNotification } from "../Notifications/showNotification"


const Form = () => {
  const [data, setData] = useState({
    name: '',
    email: '',
    password: '',
    age: '',
    image: null,
  });

  const handleInputs = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleFile = (event) => {
    setData({ ...data, image: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const auth = getAuth();
    const response = await createUserWithEmailAndPassword(auth, data.email, data.password);
    const uid = response.user.uid;
  
    const imagePath = `images/${uid}`;
    const storageRef = ref(storage, imagePath);
  
    const uploadTask = uploadBytesResumable(storageRef, data.image);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
      },
      (error) => {
        console.log(error.message);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        const usersRef = collection(database, 'users');
  
        // Request permission and get messaging token
        const messaging = getMessaging();
        try {
          await requestUserPermission();
          const token = await getToken(messaging, {vapidKey: 'BICbcSgB_Y6PpQGWpmTzzMx45FJa2Yr_tOzk-ArgjQmnam4MB6PdHWjJA6skfJzL5yZL7YR5nDlClqa8qZmkoiQ'});
          await addDoc(usersRef, {
            uid,
            name: data.name,
            email: data.email,
            age: Number(data.age),
            imagePath,
            imageUrl: downloadURL,
            messagingToken: token,
          });
        } catch (err) {
          console.log('Unable to get permission to notify.', err);
        }
      },
    );
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="name" onChange={handleInputs} />
      </label>
      <label>
        Email:
        <input type="email" name="email" onChange={handleInputs} />
      </label>
      <label>
        Password:
        <input type="password" name="password" onChange={handleInputs} />
      </label>
      <label>
        Age:
        <input type="number" name="age" onChange={handleInputs} />
      </label>
      <label>
        Image:
        <input type="file" name="image" onChange={handleFile} />
      </label>
      <button type="submit">Sign Up</button>
    </form>
  );
};

export default Form;






// import React, { useState, useEffect } from 'react';
// import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
// import { collection, addDoc } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import { database, storage } from '../firebaseConfig';
// import { requestUserPermission } from '../Notifications/requestPermission';
// import { initializeNotification } from '../Notifications/showNotification';

// const Form = () => {
//   const [data, setData] = useState({
//     name: '',
//     email: '',
//     password: '',
//     age: '',
//     image: null,
//   });

//   const handleInputs = (event) => {
//     setData({ ...data, [event.target.name]: event.target.value });
//   };

//   const handleFile = (event) => {
//     setData({ ...data, image: event.target.files[0] });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const auth = getAuth();
//     const response = await createUserWithEmailAndPassword(auth, data.email, data.password);
//     const uid = response.user.uid;

//     const imagePath = `images/${uid}`;
//     const storageRef = ref(storage, imagePath);

//     const uploadTask = uploadBytesResumable(storageRef, data.image);
//     uploadTask.on(
//       'state_changed',
//       (snapshot) => {
//         console.log('Upload is ' + (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + '% done');
//       },
//       (error) => {
//         console.log(error.message);
//       },
//       async () => {
//         const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
//         const usersRef = collection(database, 'users');
//         const token = await requestUserPermission();
       
//         console.log("Messaging token:", token);

//         if (token) {
//           await addDoc(usersRef, {
//             uid,
//             name: data.name,
//             email: data.email,
//             age: Number(data.age),
//             imagePath,
//             imageUrl: downloadURL,
//             messagingToken: token,
//           });
//         } else {
//           console.error("Messaging token is undefined");
//         }

//       },
//     );
//   };

//   useEffect(() => {
//     initializeNotification();
//   }, []);

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Name:
//         <input type="text" name="name" onChange={handleInputs} />
//       </label>
//       <label>
//         Email:
//         <input type="email" name="email" onChange={handleInputs} />
//       </label>
//       <label>
//         Password:
//         <input type="password" name="password" onChange={handleInputs} />
//       </label>
//       <label>
//         Age:
//         <input type="number" name="age" onChange={handleInputs} />
//       </label>
//       <label>
//         Image:
//         <input type="file" name="image" onChange={handleFile} />
//       </label>
//       <button type="submit">Sign Up</button>
//     </form>
//   );
// };

// export default Form;

import React from 'react'
import Navbar from "../Component/Navbar"
import App from "../App"
import UploadImage from '../Component/UploadImage'

const Home = () => {
  return (
    <div>
        
        <Navbar />

        <h1>File Upload Component</h1>
<UploadImage />
        Home
<App />
    </div>
  )
}

export default Home
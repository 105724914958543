import { getMessaging, getToken } from 'firebase/messaging';
import { messaging } from '../firebaseConfig';

export const requestUserPermission = async () => {
 
  try {
    const token = await getToken(messaging, {vapidKey: 'BICbcSgB_Y6PpQGWpmTzzMx45FJa2Yr_tOzk-ArgjQmnam4MB6PdHWjJA6skfJzL5yZL7YR5nDlClqa8qZmkoiQ'});
    console.log(token);
    return token;
  } catch (err) {
    console.log('User did not give permission to send notifications.', err);
    return null;
  }
};

// import React, { useEffect, useState } from 'react'
// import { app, database, storage, messaging } from '../firebaseConfig';
// import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,signInWithPopup, GoogleAuthProvider} from "firebase/auth"
// import {collection, addDoc, getDocs, doc, updateDoc, deleteDoc, onSnapshot, query, where} from "firebase/firestore"
// import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
// const UploadImage = () => {

//     const [data, setData] = useState({})


//     const handleFileUpload = () => {
//         console.log(data)
//         const storageRef = ref(storage, `images/${data.name}`);
//         const uploadTask = uploadBytesResumable(storageRef, data);
//         uploadTask.on('state_changed', 
//         (snapshot) => {
//           // Observe state change events such as progress, pause, and resume
//           // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//           console.log('Upload is ' + progress + '% done');
       
//         }, 
//         (error) => {
//           // Handle unsuccessful uploads
//           console.log(error.message)
//         }, 
//         () => {
//           // Handle successful uploads on complete
//           // For instance, get the download URL: https://firebasestorage.googleapis.com/...
//           getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//             console.log('File available at', downloadURL);
//           });
//         }
//       );
//       }



//   return (
//     <div>UploadImage
//          <input type="file" onChange={(event) => setData(event.target.files[0])} />
//          <button className="file-button" onClick={handleFileUpload}>File Upload</button>
//     </div>
//   )
// }

// export default UploadImage


import React, { useEffect, useState } from 'react'
import { app, database, storage, messaging } from '../firebaseConfig';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,signInWithPopup, GoogleAuthProvider} from "firebase/auth"
import {collection, addDoc, getDocs, doc, updateDoc, deleteDoc, onSnapshot, query, where} from "firebase/firestore"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const UploadImage = () => {
  const [data, setData] = useState({})
  const imagesRef = collection(database, "images");

  const handleFileUpload = () => {
    const storageRef = ref(storage, `images/${data.name}`);
    const uploadTask = uploadBytesResumable(storageRef, data);

    uploadTask.on(
      'state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      }, 
      (error) => {
        console.log(error.message)
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          
          // add this URL to the Firestore database
          addDoc(imagesRef, {
            url: downloadURL,
            name: data.name
          }).then(() => {
            console.log('Image URL stored in Firestore');
          }).catch((error) => {
            console.error('Error storing image URL: ', error);
          });
        });
      }
    );
  };

  return (
    <div>
      UploadImage
      <input type="file" onChange={(event) => setData(event.target.files[0])} />
      <button className="file-button" onClick={handleFileUpload}>File Upload</button>
    </div>
  )
}

export default UploadImage;

import React, { useEffect, useState } from 'react';
import { app, database, storage, messaging } from './firebaseConfig';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,signInWithPopup, GoogleAuthProvider} from "firebase/auth"
import {collection, addDoc, getDocs, doc, updateDoc, deleteDoc, onSnapshot, query, where} from "firebase/firestore"
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {  getToken } from "firebase/messaging";



import "./App.css"
const  App = () => {

  let auth = getAuth()
  const googleProvider = new GoogleAuthProvider()
  const collectionRef = collection( database, "users")
  const ageQuery = query(collectionRef, where("age", "==", 21));

  const [data, setData] = useState({})
  const handleInputs = (event) => {
    let inputs = { [event.target.name]: event.target.value }

    setData({ ...data, ...inputs })
  }


const handleFileUpload = () => {
  console.log(data)
  const storageRef = ref(storage, `images/${data.name}`);
  const uploadTask = uploadBytesResumable(storageRef, data);
  uploadTask.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
 
  }, 
  (error) => {
    // Handle unsuccessful uploads
    console.log(error.message)
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      console.log('File available at', downloadURL);
    });
  }
);
}



  const handleSubmit = () => {
    signInWithEmailAndPassword(auth, data.email, data.password).then((response) => {
console.log(response.user)
      })
      
   .catch( (err) => {
      alert(err.message)
    })
  };

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleProvider).then((response) => {
console.log(response.user)
      })
      
   .catch( (err) => {
      alert(err.message)
    })
  };

  const handleSignup = () => {
    createUserWithEmailAndPassword(auth, data.email, data.password).then((response) => {
      console.log(response.user)
    } ).catch((err) => {
      alert(err.message)
    })
  }


  const handleDBInput = () => {
    addDoc(collectionRef, {
      email: data.email,
      name: data.name,
      age: Number(data.age),
      password:data.password
    }).then((response) => {
      alert("Data Added Successfully")
    }).catch((err) => {
      alert(err.message)
    } )
  }


  // const getData = () => {
  //   // getDocs(collectionRef, "users").then((response) => console.log(response.docs.map((item) => {
  //   //   return {...item.data(), id:item.id}
  //   // })))

  //   onSnapshot(ageQuery, (data) => {
  //     console.log(data.docs.map((item) => {
  //       return item.data()
  //     })
  //     )
  //   })
  // }


  const getData = () => {
    onSnapshot(ageQuery, (snapshot) => {
      console.log(snapshot.docs.map((item) => item.data()));
    }, (error) => {
      console.log("Error fetching data:", error);
    });
  };
  


  const updateData = () => {
    const docToUpdate = doc(database, "users" , "BgnSic4EjuIs2vDedfd4")
    updateDoc(docToUpdate, {
      email: "mahi123@gmail.com",
      password: "123456"
    }).then(() => {
      alert("Data Updaated")
    } ).catch((err) => {
      alert(err.message)
    })

  }


const deleteData = () => {
  const docToDelete = doc(database, "users", "RrYXfy5utZrdcyjThpXS")
  deleteDoc(docToDelete).then(() => {
    alert("Data Deleted Succesfully")
  }).catch((err) => {
    alert(err.message)
  })
}
 


useEffect(() => {
  console.log("getData called"); // Check if the useEffect is triggered
  getData();
}, []);


  return (
    <div className="container">
    <div className="App-header">
      <h1>Sample App</h1>
      <input type="file" onChange={(event) => setData(event.target.files[0])} />
  
      <div className="input-group">
        <input
          placeholder="Name"
          name="name"
          type="text"
          className="input-fields"
          onChange={(event) => handleInputs(event)}
        />
  
        <input
          placeholder="Email"
          name="email"
          type="email"
          className="input-fields"
          onChange={(event) => handleInputs(event)}
        />
  
        <input
          placeholder="Password"
          name="password"
          type="password"
          className="input-fields"
          onChange={(event) => handleInputs(event)}
        />
  
        <input
          placeholder="Age"
          type="number"
          name="age"
          className="input-fields"
          onChange={(event) => handleInputs(event)}
        />
      </div>
  
      <div className="button-group">
        <button className="file-button" onClick={handleFileUpload}>File Upload</button>
        <button className="submit-button" onClick={handleSubmit}>Submit</button>
        <button className="signup-button" onClick={handleSignup}>Signup</button>
      </div>
      <div className="button-group">
        <button className="signin-button" onClick={handleGoogleLogin}>Google Signin</button>
        <button className="db-input-button" onClick={handleDBInput}>DB Input</button>
        <button className="db-data-button" onClick={getData}>DB Data</button>
      </div>
      <div className="button-group">
        <button className="db-update-button" onClick={updateData}>DB Update</button>
        <button className="db-delete-button" onClick={deleteData}>DB Delete</button>
        {/* <button onClick={addData}>Log In</button> */}
        {/* <button onClick={handlelogout}>Log out</button> */}
      </div>
    </div>
  </div>
  
  );
}

export default App;

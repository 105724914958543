import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const ShowForm = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(database, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map(doc => doc.data());
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  return (
    <div>
      {users.map((user, index) => (
        <div key={index}>
          <h2>{user.name}</h2>
          <p>Email: {user.email}</p>
          <p>Age: {user.age}</p>
          <img src={user.imageUrl} alt="User" style={{ width: '100px', height: '100px' }} />
        </div>
      ))}
    </div>
  );
};

export default ShowForm;

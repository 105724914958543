import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import your components here
import Home from '../Pages/Home';
import App from "../App"
import ShowImages from '../Component/ShowImages';
import Form from '../Component/SignupForm';
import ShowForm from '../Component/ShowForm';


const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/showimages" element={<ShowImages />} />
        <Route exact path="/form" element={<Form />} />
        <Route exact path='/showform' element={<ShowForm />} />
       
       <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
import { getStorage, ref } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7-QnJ5hNdxsh3zqTE5SNVEyW036CzYic",
  authDomain: "ravidassia-15603.firebaseapp.com",
  databaseURL: "https://ravidassia-15603-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ravidassia-15603",
  storageBucket: "ravidassia-15603.appspot.com",
  messagingSenderId: "286949742250",
  appId: "1:286949742250:web:bc8f6025b6c497240722d3",
  measurementId: "G-RX6GBTWDWT"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app)
export const storage = getStorage(app)
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);